.home {
  text-align: center;
  padding: 20px;
}

.home-heading {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none; /* Remove underline */
  background: linear-gradient(to right, #f9f9f9, #ddd); /* Background gradient */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  animation: fadeIn 2s ease-in-out; /* Simple fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gray-section {
  background-color: #ccc;
  padding: 20px;
  margin: 20px auto;
  width: 25%;
  border-radius: 25px;
}

.gray-section p {
  font-size: 1.5rem;
  margin: 0 0 20px;
}

.gray-section .highlight {
  color: #007bff;
  font-weight: bold;
}

.gray-section .button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.gray-section .button:hover {
  background-color: #0056b3;
}

.info-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  animation: fadeInUp 1.5s ease-in-out; /* Simple fade-in-up animation */
  display: flex;
  flex-wrap: wrap; /* Ensures responsiveness */
  align-items: flex-start; /* Aligns content at the top */
  justify-content: center; /* Centers the content */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.info-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%; /* Ensures full width */
  justify-content: space-between; /* Adds space between the steps and image */
}

.steps {
  flex: 1;
  min-width: 300px; /* Ensures a minimum width */
  max-width: 50%; /* Limits the maximum width */
  margin-right: 20px; /* Adds space between the steps and image */
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.step-number {
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
  margin-right: 20px;
}

.step-description {
  flex: 1;
}

.step-description h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.step-description p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.info-images {
  flex: 1;
  min-width: 300px; /* Ensures a minimum width */
  max-width: 50%; /* Limits the maximum width */
  display: flex;
  flex-direction: column; /* Stack images vertically */
  justify-content: center;
  align-items: center;
}

.info-images img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 20px; /* Adds space between images */
}