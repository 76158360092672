.contact {
    text-align: center;
    padding: 20px;
  }
  
  .contact h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 10px;
  }
  