header {
  background-color: #282c34;
  color: rgb(0, 119, 255);
  padding: 20px;
  text-align: center;
  position: relative;
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out items */
}

header h1 {
  margin: 0;
  font-size: 2rem;
}

.logo {
  height: 50px; /* Adjust size as needed */
  margin-right: 20px; /* Space between logo and heading */
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: rgb(0, 195, 255);
  text-decoration: none;
  font-size: 1.2rem;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.3s ease;
}

nav ul li a:hover {
  background-color: rgba(0, 195, 255, 0.1);
  border-color: rgb(0, 195, 255);
}
